var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{attrs:{"show":!_vm.isFetchingSecrets,"control-show":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Secrets ")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" Manage the "),_c('a',{attrs:{"href":"https://docs.prefect.io/cloud/cloud_concepts/secrets.html","target":"_blank","rel":"noopener noreferrer"}},[_vm._v("secrets")]),_c('sup',[_c('v-icon',{attrs:{"x-small":""}},[_vm._v(" open_in_new ")])],1),_vm._v(" required by your team's flow executions ")]},proxy:true},(!_vm.permissionsCheck)?{key:"cta",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary","large":""},on:{"click":function($event){_vm.previousSecretName = null
        _vm.isSecretUpdate = false
        _vm.secretModifyDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" add ")]),_vm._v(" Add Secret ")],1)]},proxy:true}:null,(_vm.permissionsCheck)?{key:"alerts",fn:function(){return [_c('v-alert',{staticClass:"mx-auto",attrs:{"border":"left","colored-border":"","elevation":"2","type":"warning","tile":"","icon":"lock","max-width":"380"}},[_vm._v(" You don't have permission to manage secrets. ")])]},proxy:true}:null],null,true)},[(!_vm.$vuetify.breakpoint.mdAndUp && !_vm.permissionsCheck)?_c('v-text-field',{staticClass:"rounded-0 elevation-1 mb-1",attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for a secret","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-card',{staticClass:"mx-auto",attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp && !_vm.permissionsCheck)?_c('div',{staticClass:"py-1 mr-2 flex"},[(!_vm.permissionsCheck)?_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '360px' : null
          }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for a secret","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1):_vm._e(),(!_vm.permissionsCheck)?_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",attrs:{"fixed-header":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"search":_vm.search,"items":_vm.secretNames,"items-per-page":_vm.itemsPerPage,"sort-by":"name","footer-props":{
          firstIcon: 'first_page',
          itemsPerPageOptions: _vm.rowsPerPageItems,
          lastIcon: 'last_page',
          nextIcon: 'keyboard_arrow_right',
          prevIcon: 'keyboard_arrow_left',
          showFirstLastPage: true
        },"no-results-text":"No secrets found. Try expanding your search?","no-data-text":"Your team does not have any secrets yet."},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.openSecretModifyDialog(item)}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,true)},[_vm._v(" Modify secret ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){return _vm.openSecretDeleteDialog(item)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Delete secret ")])]}}],null,false,3478750529)}):_vm._e()],1)],1),_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '75vh' },"loading":_vm.isSettingSecret,"title":_vm.isSecretUpdate ? 'Modify Secret' : 'Create New Secret',"data-private":""},on:{"cancel":_vm.resetSelectedSecret,"confirm":_vm.validateAndSetSecret},model:{value:(_vm.secretModifyDialog),callback:function ($$v) {_vm.secretModifyDialog=$$v},expression:"secretModifyDialog"}},[(!_vm.isSecretUpdate)?_c('span',[_vm._v(" Add a secret that will allow your team to access sensitive data during flow executions. ")]):_vm._e(),_c('v-text-field',{staticClass:"_lr-hide mt-6",attrs:{"data-private":"","autofocus":!_vm.isSecretUpdate,"single-line":"","outlined":"","dense":"","messages":_vm.secretExists
          ? 'A secret with this this name already exists. Clicking CONFIRM will overwrite it.'
          : null,"rules":[_vm.rules.required],"placeholder":"Secret Name","prepend-inner-icon":"vpn_key","validate-on-blur":""},model:{value:(_vm.secretNameInput),callback:function ($$v) {_vm.secretNameInput=$$v},expression:"secretNameInput"}}),(_vm.secretModifyDialog)?_c('code-input',{ref:"secretValueInput",staticClass:"text-body-1",attrs:{"placeholder":"Secret value","editors":['text', 'json'],"data-private":""},model:{value:(_vm.secretValueInput),callback:function ($$v) {_vm.secretValueInput=$$v},expression:"secretValueInput"}}):_vm._e()],1),_c('ConfirmDialog',{attrs:{"type":"error","confirm-text":"Delete","dialog-props":{ 'max-width': '500' },"loading":_vm.isDeletingSecret,"title":"Are you sure you want to delete this secret?"},on:{"confirm":function($event){return _vm.deleteSecret(_vm.selectedSecret)}},model:{value:(_vm.secretDeleteDialog),callback:function ($$v) {_vm.secretDeleteDialog=$$v},expression:"secretDeleteDialog"}},[_vm._v(" This action cannot be undone. ")]),_c('Alert',{attrs:{"type":_vm.alertType,"message":_vm.alertMessage,"offset-x":_vm.$vuetify.breakpoint.mdAndUp ? 256 : 56},model:{value:(_vm.alertShow),callback:function ($$v) {_vm.alertShow=$$v},expression:"alertShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }